<template>
  <div
    class="w-full container flex flex-col items-center justify-between px-5 text-gray-700"
  >
    <div class="mb-10 w-full">
      <div class="md:grid md:grid-cols-1 md:gap-6">
        <div class="md:col-span-1">
          <div class="flex flex-col justify-items items-start px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Editar datos del perfil
            </h3>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form @submit.prevent="handleSubmit">
            <div class="border border-gray-200 rounded-md overflow-hidden">
              <div class="px-4 py-5 bg-white space-y-6 sm:px-6">
                <div class="grid grid-cols-3 gap-6">
                  <div class="col-span-3">
                    <div
                      class="w-full flex flex-row justify-start items-center mb-1"
                    >
                      <div class="flex flex-col justify-start items-start">
                        <label
                          class="w-full text-left block text-sm text-left font-medium text-gray-700"
                        >
                          Nombres y Apellidos
                        </label>
                        <label
                          class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
                        >
                          Escribir tal como aparecen en carnet de identidad.
                        </label>
                      </div>
                    </div>
                    <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-2">
                      <!-- names -->
                      <div class="flex flex-col justify-center items-start">
                        <label
                          for="names"
                          class="block text-sm font-medium text-gray-700"
                          >Nombres</label
                        >
                        <div class="mt-1 w-full">
                          <input
                            v-model="register_data.names"
                            type="text"
                            name="names"
                            id="names"
                            :class="
                              submitted && $v.register_data.names.$error
                                ? 'border-red-600'
                                : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                            "
                            class="block border w-full rounded-md shadow-sm sm:text-sm"
                            placeholder="Escribe aquí"
                          />
                        </div>
                        <div
                          v-if="submitted && !$v.register_data.names.required"
                          class="text-sm text-red-600"
                        >
                          Los nombres son obligatorios
                        </div>
                      </div>
                      <!-- lastnames -->
                      <div class="flex flex-col justify-center items-start">
                        <label
                          for="lastnames"
                          class="block text-sm font-medium text-gray-700"
                          >Apellidos</label
                        >
                        <div class="mt-1 w-full">
                          <input
                            v-model="register_data.lastnames"
                            type="text"
                            name="lastnames"
                            id="lastnames"
                            :class="
                              submitted && $v.register_data.lastnames.$error
                                ? 'border-red-600'
                                : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                            "
                            class="block border w-full rounded-md shadow-sm sm:text-sm"
                            placeholder="Escribe aquí"
                          />
                        </div>
                        <div
                          v-if="
                            submitted && !$v.register_data.lastnames.required
                          "
                          class="text-sm text-red-600"
                        >
                          Los apellidos son obligatorios
                        </div>
                      </div>
                    </div>
                    <div
                      class="w-full mt-3 flex flex-row justify-start items-center mb-1"
                    >
                      <div class="flex flex-col justify-start items-start">
                        <label
                          class="w-full text-left block text-sm text-left font-medium text-gray-700"
                        >
                          Rol Único Tributario (RUT)
                        </label>
                      </div>
                    </div>
                    <div class="w-full grid grid-cols-1 gap-x-2">
                      <!-- rut -->
                      <div class="flex flex-col justify-center items-start">
                        <div class="mt-1 w-full">
                          <input
                            v-model="register_data.rut"
                            type="text"
                            disabled
                            class="border-gray-300 bg-gray-100 block border w-full rounded-md shadow-sm sm:text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="user.roles.find(e => e.name === 'patient')"
                    class="col-span-3"
                  >
                    <!-- email -->
                    <div
                      class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
                    >
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                        >Correo de contacto</label
                      >
                      <label
                        class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
                      >
                        Diferente al correo con que ingresas a tu cuenta.
                      </label>
                      <div class="mt-1 w-full">
                        <input
                          v-model="register_data.contact"
                          type="text"
                          name="email"
                          id="email"
                          :class="
                            submitted && $v.register_data.contact.$error
                              ? 'border-red-600'
                              : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                          "
                          class="block border w-full rounded-md shadow-sm sm:text-sm"
                          placeholder="xxxxx@xxxxx.xxx"
                        />
                      </div>
                      <div
                        v-if="submitted && !$v.register_data.contact.email"
                        class="text-sm text-red-600"
                      >
                        El correo de contacto no es válido
                      </div>
                    </div>
                  </div>
                  <div class="col-span-3">
                    <label
                      id="listbox-label"
                      class="w-full text-left block text-sm text-left font-medium text-gray-700"
                    >
                      Número teléfono
                    </label>
                    <!-- <label
                      class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Incidunt adipisci facilis, deleniti repellendus nisi
                      voluptatibus.
                    </label> -->
                    <div class="mb-2 w-full grid grid-cols-3 gap-x-2">
                      <div class="w-full relative">
                        <button
                          @click="
                            () => {
                              countrySelector = !countrySelector;
                            }
                          "
                          v-click-outside="
                            () => {
                              countrySelector = false;
                            }
                          "
                          type="button"
                          class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                          aria-haspopup="listbox"
                          aria-expanded="true"
                          aria-labelledby="listbox-label"
                        >
                          <span class="flex items-center">
                            <img
                              :src="country.flag"
                              alt=""
                              class="flex-shrink-0 h-6 w-6"
                            />
                            <span class="ml-3 block truncate">
                              ({{ country.dialCode }}) {{ country.name }}
                            </span>
                          </span>
                          <span
                            class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                          >
                            <!-- Heroicon name: solid/selector -->
                            <svg
                              class="h-5 w-5 text-gray-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </span>
                        </button>

                        <transition
                          enter-active-class=""
                          enter-class=""
                          enter-to-class=""
                          leave-active-class="transition ease-in duration-100"
                          leave-class="opacity-100"
                          leave-to-class="opacity-0"
                        >
                          <ul
                            v-if="countrySelector"
                            class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                            tabindex="-1"
                            role="listbox"
                            aria-labelledby="listbox-label"
                            aria-activedescendant="listbox-option-3"
                          >
                            <li
                              v-for="(countryElement,
                              countryIndex) in countries"
                              :key="countryIndex"
                              class="cursor-default select-none relative py-2 pl-3 pr-9"
                              :class="
                                countryElement.isoCode === country.isoCode
                                  ? 'bg-gray-200'
                                  : 'text-gray-900'
                              "
                              id="listbox-option-0"
                              role="option"
                              @click="
                                () => {
                                  country = countryElement;
                                }
                              "
                            >
                              <div class="flex items-center">
                                <img
                                  :src="countryElement.flag"
                                  alt=""
                                  class="flex-shrink-0 h-6 w-6"
                                />
                                <span
                                  class="ml-3 block truncate"
                                  :class="
                                    countryElement.isoCode === country.isoCode
                                      ? 'font-semibold'
                                      : 'font-normal'
                                  "
                                >
                                  ({{ countryElement.dialCode }})
                                  {{ countryElement.name }}
                                </span>
                              </div>

                              <span
                                class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                :class="
                                  countryElement.isoCode === country.isoCode
                                    ? 'text-green-500'
                                    : 'hidden'
                                "
                              >
                                <!-- Heroicon name: solid/check -->
                                <svg
                                  class="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                            </li>

                            <!-- More items... -->
                          </ul>
                        </transition>
                      </div>
                      <!-- lastnames -->
                      <div
                        class="col-span-2 flex flex-col justify-center items-start"
                      >
                        <div class="w-full">
                          <input
                            v-model="register_data.phone"
                            type="text"
                            name="lastnames"
                            id="lastnames"
                            :class="
                              submitted && $v.register_data.phone.$error
                                ? 'border-red-600'
                                : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                            "
                            class="block border w-full rounded-md shadow-sm sm:text-sm"
                            placeholder="Escribe aquí"
                          />
                        </div>
                        <div
                          v-if="submitted && !$v.register_data.phone.required"
                          class="text-sm text-red-600"
                        >
                          El teléfono es obligatorio
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
              <div
                v-if="user.roles.find(e => e.name === 'patient')"
                class="px-4 pb-5 bg-white space-y-6 sm:px-6"
              >
                <div class="grid grid-cols-3 gap-6">
                  <div class="col-span-3">
                    <label
                      class="w-full text-left block text-sm text-left font-medium text-gray-700"
                    >
                      Fecha de nacimiento
                      <span class="font-xs font-light mr-2" v-if="age != null"
                        >/</span
                      >
                      <span class="font-bold" v-if="age != null">{{
                        age
                      }}</span>
                    </label>
                    <div
                      class="mb-2 w-full grid grid-cols-1 md:grid-cols-3 gap-2"
                    >
                      <select
                        v-model="register_data.day"
                        class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      >
                        <option selected disabled :value="''">Día</option>
                        <option
                          v-for="(day, dayIndex) in days_list"
                          :key="dayIndex"
                          >{{ day }}</option
                        > </select
                      ><select
                        v-model="register_data.month"
                        class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      >
                        <option selected disabled :value="''">Mes</option>
                        <option
                          v-for="(month, monthIndex) in months"
                          :key="monthIndex"
                          :value="monthIndex + 1"
                          >{{ month }}</option
                        > </select
                      ><select
                        v-model="register_data.year"
                        class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      >
                        <option selected disabled :value="''">Año</option>
                        <option
                          v-for="(year, yearIndex) in years_list()"
                          :key="yearIndex"
                          >{{ year }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
              <div
                v-if="user.roles.find(e => e.name === 'patient')"
                class="px-4 pb-5 bg-white space-y-6 sm:px-6"
              >
                <div class="grid grid-cols-3 gap-6">
                  <div class="col-span-3">
                    <label
                      for="country"
                      class="w-full text-left block text-left text-sm font-medium text-gray-700"
                      >Previsión de salud</label
                    >
                    <select
                      name="prevision"
                      autocomplete="prevision"
                      v-model="register_data.prevision"
                      class="col-span-6 mb-2 block w-full py-2 px-3 disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                    >
                      <option selected disabled :value="null"
                        >Seleccionar</option
                      >
                      <option
                        v-for="(prevision, previsionIndex) in previsions"
                        :key="previsionIndex"
                        >{{ prevision }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>

              <!--  -->
              <div class="px-4 pb-5 bg-white space-y-2 sm:px-6">
                <div
                  class="bg-white space-y-2"
                  v-if="
                    user.roles.find(e => e.name === 'doctor') ||
                      user.roles.find(e => e.name === 'psychologist')
                  "
                >
                  <div
                    class="col-span-6 flex flex-col justify-center items-start"
                  >
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Cuenta bancaria
                    </h3>
                    <p class="mt-1 text-left max-w-2xl text-sm text-gray-500">
                      Datos bancarios para transferencias.
                    </p>
                  </div>
                  <!-- bank -->
                  <div
                    class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
                  >
                    <label
                      for="bank"
                      class="block text-sm font-medium text-gray-700"
                      >Selecciona el banco</label
                    >
                    <div class="mt-1 w-full">
                      <select
                        name="bank"
                        autocomplete="bank"
                        v-model="register_data.bank"
                        :class="
                          submitted && $v.register_data.bank.$error
                            ? 'border-red-600'
                            : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                        "
                        class="block border w-full rounded-md shadow-sm sm:text-sm"
                      >
                        <option selected disabled :value="null"
                          >Seleccionar</option
                        >
                        <option
                          v-for="(bank, bankIndex) in banks.filter(
                            e => e.tipo == 'Bancos'
                          )"
                          :key="bankIndex"
                          >{{ bank.nombre }}</option
                        >
                      </select>
                    </div>
                    <div
                      v-if="submitted && !$v.register_data.bank.required"
                      class="text-sm text-red-600"
                    >
                      El banco es obligatorio
                    </div>
                  </div>
                  <!-- type -->
                  <div
                    class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
                  >
                    <label
                      for="type"
                      class="block text-sm font-medium text-gray-700"
                      >Tipo de cuenta</label
                    >
                    <div class="mt-1 w-full">
                      <select
                        name="type"
                        autocomplete="type"
                        v-model="register_data.type"
                        :class="
                          submitted && $v.register_data.type.$error
                            ? 'border-red-600'
                            : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                        "
                        class="block border w-full rounded-md shadow-sm sm:text-sm"
                      >
                        <option selected disabled :value="null"
                          >Seleccionar</option
                        >
                        <option
                          v-for="(type, typeIndex) in types"
                          :key="typeIndex"
                          >{{ type }}</option
                        >
                      </select>
                    </div>
                    <div
                      v-if="submitted && !$v.register_data.type.required"
                      class="text-sm text-red-600"
                    >
                      El tipo de cuenta es obligatorio
                    </div>
                  </div>
                  <!-- number -->
                  <div
                    class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
                  >
                    <label
                      for="number"
                      class="block text-sm font-medium text-gray-700"
                      >Número de cuenta</label
                    >
                    <div class="mt-1 w-full">
                      <input
                        v-model="register_data.number"
                        type="number"
                        name="number"
                        id="number"
                        :class="
                          submitted && $v.register_data.number.$error
                            ? 'border-red-600'
                            : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                        "
                        class="block border w-full rounded-md shadow-sm sm:text-sm"
                        placeholder="Escribe aquí"
                      />
                    </div>
                    <div
                      v-if="submitted && !$v.register_data.number.required"
                      class="text-sm text-red-600"
                    >
                      El número de cuenta es obligatorio
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-3 gap-6">
                  <div class="col-span-3">
                    <label
                      class="w-full mt-4 text-left block text-sm text-left font-medium text-gray-700"
                    >
                      Dirección de factura
                    </label>
                    <!-- <label
                      class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Incidunt adipisci facilis, deleniti repellendus nisi
                      voluptatibus.
                    </label> -->
                    <div
                      class="w-full mb-2 grid gap-x-2 grid-cols-1 md:grid-cols-2"
                    >
                      <select
                        v-model="register_data.region"
                        class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      >
                        <option selected disabled :value="''">Region</option>
                        <option
                          v-for="(region, regionIndex) in regiones"
                          :key="regionIndex"
                          >{{ region.region }}</option
                        >
                      </select>
                      <select
                        name="comuna"
                        autocomplete="comuna"
                        v-model="register_data.comuna"
                        class="col-span-2 md:col-span-1 mt-2 md:mt-0 w-full disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      >
                        <option selected disabled :value="''">Comuna</option>
                        <option
                          v-for="(comuna, comunaIndex) in comunas"
                          :key="comunaIndex"
                          >{{ comuna }}</option
                        >
                      </select>
                      <input
                        v-model="register_data.address"
                        name="rut"
                        type="text"
                        autocomplete="address"
                        required
                        class="col-span-2 mt-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                        placeholder="Dirección"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->

              <div class="px-4 py-3 bg-gray-50 flex justify-end sm:px-6">
                <button
                  type="submit"
                  class="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-50 bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countries from '@/utils/country-codes.json';
import moment from 'moment';
import regiones from '@/utils/comunas-regiones.json';
import banks from '@/utils/bancos.json';
import { mapGetters, mapActions } from 'vuex';
import ClickOutside from 'vue-click-outside';
import Swal from 'sweetalert2';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'Profile',
  data() {
    return {
      errorEmailRegister: false,
      comunas: [],
      regiones: regiones,
      banks: banks,
      types: ['Corriente', 'Vista'],
      countrySelector: false,
      register_data: {
        rut: '',
        phone: '',
        phone_confirmation: '',
        prevision: null,
        birthdate: null,
        day: '',
        month: '',
        year: '',
        note: '',
        region: '',
        comuna: '',
        address: '',
        names: '',
        lastnames: '',
        contact: '',
        bank: null,
        type: null,
        number: '',
      },
      previsions: [
        'Fondo Nacional De Salud (Fonasa)',
        'Isalud Isapre De Codelco',
        'Isapre Banmédica',
        'Isapre Colmena',
        'Isapre Consalud',
        'Isapre Cruz Blanca',
        'Isapre Cruz Del Norte',
        'Isapre Fundación Banco Estado',
        'Isapre Nueva Másvida',
        'Isapre Vida Tres',
        'Particular',
        'Isapre Esencial',
      ],
      errorRut: false,
      country: {
        name: 'Chile',
        dialCode: '+56',
        isoCode: 'CL',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cl.svg',
      },
      countries: countries,
      submitted: false,
    };
  },
  validations: {
    register_data: {
      names: {
        required,
      },
      lastnames: {
        required,
      },
      contact: {
        email,
      },
      phone: {
        required,
      },
      bank: {
        required,
      },
      type: {
        required,
      },
      number: {
        required,
      },
    },
  },
  created() {
    if (this.user.roles.find(e => e.name === 'doctor')) {
      this.register_data.rut = this.user.doctor.rut;
      this.register_data.phone = this.user.doctor.phone;
      this.register_data.bank = this.user.doctor.bank;
      this.register_data.type = this.user.doctor.type;
      this.register_data.number = this.user.doctor.number;
    } else if (this.user.roles.find(e => e.name === 'superdoctor')) {
      this.register_data.rut = this.user.superdoctor.rut;
      this.register_data.phone = this.user.superdoctor.phone;
      this.register_data.bank = this.user.superdoctor.bank;
      this.register_data.type = this.user.superdoctor.type;
      this.register_data.number = this.user.superdoctor.number;
    } else if (this.user.roles.find(e => e.name === 'psychologist')) {
      this.register_data.rut = this.user.psychologist.rut;
      this.register_data.phone = this.user.psychologist.phone;
      this.register_data.bank = this.user.psychologist.bank;
      this.register_data.type = this.user.psychologist.type;
      this.register_data.number = this.user.psychologist.number;
    } else if (this.user.roles.find(e => e.name === 'patient')) {
      this.register_data.rut = this.user.patient.rut;
      this.register_data.contact = this.user.patient.contact;
      this.register_data.phone = this.user.patient.phone;
      this.register_data.prevision = this.user.patient.health;
      let birth = moment(this.user.patient.birthdate, 'YYYY-MM-DD HH:mm');
      this.register_data.year = birth.year();
      setTimeout(() => {
        this.register_data.month = birth.month() + 1;
        setTimeout(() => {
          this.register_data.day = birth.date();
        }, 500);
      }, 500);
    }

    this.register_data.names = this.user.names;
    this.register_data.lastnames = this.user.lastnames;
    let address = this.user.addresses.find(e => e.main);
    if (address) {
      this.register_data.comuna = address.comuna;
      this.register_data.address = address.address;
      this.register_data.region = address.region;
    }
  },
  methods: {
    ...mapActions('authentication', ['updateProfile']),
    validateEmail: email => {
      const regex = /\S+@\S+\.\S+/;
      return regex.test(email);
    },
    handleSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.updateProfile({
        names: this.register_data.names,
        lastnames: this.register_data.lastnames,
        patient: this.user.roles.find(e => e.name === 'patient')
          ? {
              phone: this.register_data.phone,
              contact: this.register_data.contact,
              health: this.register_data.prevision,
              birthdate: this.register_data.birthdate,
            }
          : {},
        doctor: this.user.roles.find(e => e.name === 'doctor')
          ? {
              phone: this.register_data.phone,
              bank: this.register_data.bank,
              type: this.register_data.type,
              number: this.register_data.number,
            }
          : {},
        superdoctor: this.user.roles.find(e => e.name === 'superdoctor')
          ? {
              phone: this.register_data.phone,
              bank: this.register_data.bank,
              type: this.register_data.type,
              number: this.register_data.number,
            }
          : {},
        psychologist: this.user.roles.find(e => e.name === 'psychologist')
          ? {
              phone: this.register_data.phone,
              bank: this.register_data.bank,
              type: this.register_data.type,
              number: this.register_data.number,
            }
          : {},
        address: {
          region: this.register_data.region,
          comuna: this.register_data.comuna,
          address: this.register_data.address,
        },
      })
        .then(() => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Datos actualizados!',
            timer: 10000,
            toast: true,
            confirmButtonColor: '#13b981',
            timerProgressBar: true,
            showClass: {
              popup: 'animate__animated animate__fadeIn',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOut',
            },
          });
        })
        .catch(err => {
          if (
            err.message &&
            err.message == 'Request failed with status code 422'
          ) {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'El email de contacto ya está en uso!',
              timer: 10000,
              toast: true,
              confirmButtonColor: '#13b981',
              timerProgressBar: true,
              showClass: {
                popup: 'animate__animated animate__fadeIn',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOut',
              },
            });
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'No se guardaron los datos!',
              timer: 10000,
              toast: true,
              confirmButtonColor: '#13b981',
              timerProgressBar: true,
              showClass: {
                popup: 'animate__animated animate__fadeIn',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOut',
              },
            });
          }
        });
    },
    years_list() {
      const years = back => {
        const year = new Date().getFullYear();
        return Array.from(
          { length: back },
          (v, i) => year - back + i + 1
        ).reverse();
      };

      return years(100);
    },
    validateRut: rut => {
      var Fn = {
        // Valida el rut con su cadena completa "XXXXXXXX-X"
        validaRut: function(rutCompleto) {
          if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
          var tmp = rutCompleto.split('-');
          var digv = tmp[1];
          var rut = tmp[0];
          if (digv == 'K') digv = 'k';
          return Fn.dv(rut) == digv;
        },
        dv: function(T) {
          var M = 0,
            S = 1;
          for (; T; T = Math.floor(T / 10))
            S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
          return S ? S - 1 : 'k';
        },
      };
      return Fn.validaRut(rut);
    },
  },
  watch: {
    'register_data.contact'() {
      if (this.register_data.contact != '') {
        this.errorEmailRegister = !this.validateEmail(
          this.register_data.contact
        );
      } else {
        this.errorEmailRegister = false;
      }
    },
    'register_data.region'() {
      this.comunas = this.regiones.find(
        e => e.region === this.register_data.region
      )
        ? this.regiones.find(e => e.region === this.register_data.region)
            .comunas
        : [];
    },
    'register_data.rut'() {
      if (this.register_data.rut != '') {
        this.errorRut = !this.validateRut(this.register_data.rut);
      } else {
        this.errorRut = false;
      }
    },
    'register_data.day'() {
      if (
        this.register_data.day != '' &&
        this.register_data.month != '' &&
        this.register_data.year != ''
      ) {
        this.register_data.birthdate =
          this.register_data.day +
          '/' +
          this.register_data.month +
          '/' +
          this.register_data.year;
      } else {
        this.register_data.birthdate = '';
      }
    },
    'register_data.month'() {
      this.register_data.day = '';
    },
    'register_data.year'() {
      this.register_data.month = '';
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
    age() {
      if (this.register_data.birthdate) {
        let date = moment(this.register_data.birthdate, 'DD/MM/YYYY');
        let years = moment().diff(date, 'years', false);
        let months = moment().diff(date, 'months', false);
        return (
          years +
          ' año' +
          (years != 1 ? 's' : '') +
          ' y ' +
          (months - 12 * years) +
          ' mes' +
          (months - 12 * years != 1 ? 'es' : '')
        );
      }
      return null;
    },
    months() {
      moment.locale('es');
      if (this.register_data.year != '') {
        if (parseInt(this.register_data.year) == moment().year()) {
          console.log();
          let array = [];
          let selection = moment.monthsShort();
          for (let i = 0; i <= moment().month(); i++) {
            array.push(selection[i]);
          }
          return array;
        } else {
          return moment.monthsShort();
        }
      }
      return [];
    },
    days_list() {
      if (this.register_data.month != '' && this.register_data.year != '') {
        let dayInMonth = moment(
          this.register_data.year + '-' + this.register_data.month,
          'YYYY-MM'
        ).daysInMonth();
        let array = [];
        for (let i = 1; i <= dayInMonth; i++) {
          array.push(i);
        }
        return array;
      }
      return [];
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
.error-link-group {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.error-link {
  cursor: pointer;
  color: #e5e7eb;
  text-decoration: underline;
}
.error-link:not(:last-child) {
  margin-right: 1em;
}
</style>
